import { useState, useEffect } from "react";
import { EventRegister } from "react-native-event-listeners";
import { apiGetRequest } from "../_shared/api";
import { urls } from "../_shared/constants";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import {
  Column,
  Container,
  Row,
  Row2,
  RowNoMinMax,
} from "../_shared/styledComponents";
import { EventsEnum } from "../_shared/types";
import { GridColDef } from "@mui/x-data-grid";
import NoResults from "../_shared/components/NoResults";
import DataGridTable from "../_shared/components/datagridTable";
import { Box, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";
import { HeaderGreenTopBar, HeaderText } from "../_shared/styled";
import { StyledTextField, StyledTextField2 } from "../Auth/AuthPage";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // Import a custom chevron icon (you can use any icon)
import ChevronIcon from "../_shared/assets/svg/chevron.svg";
import { ChevronIconComponent } from "../_shared/components/ChevronIcon";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
const CustomTooltip = ({
  active,
  payload,
  label,
}: {
  active: any;
  payload: any;
  label: any;
}) => {
  if (active && payload && payload.length) {
    const { value, name } = payload?.[0] ?? { value: null, name: null };
    const { value: value2, name: name2 } = payload?.[1] ?? {
      value: null,
      name: null,
    };

    return (
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          padding: "10px",
          borderRadius: "4px",
          color: "white",
        }}
      >
        <Row spacing={10}>
          <p style={{ margin: 0, fontWeight: "bold" }}>{name}:</p>
          <p style={{ margin: 0, color: "cyan" }}>{value}</p> {/* Text color */}
        </Row>
        <Row spacing={10}>
          <p style={{ margin: 0, fontWeight: "bold" }}>{name2}:</p>
          <p style={{ margin: 0, color: "cyan" }}>{value2}</p>{" "}
          {/* Text color */}
        </Row>
      </div>
    );
  }
  return null;
};
const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = [
  "rgba(154, 137, 255, 0.7)",
  "rgba(12, 175, 109, 1)",
  "rgba(37, 94, 135, 1)",
  "rgba(121, 194, 216, 1)",
  "rgba(251, 227, 142, 1)",
  "rgba(253, 208, 159, 1)",
];


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const dataKeyValue = data[index].name; // Assuming 'name' is the dataKey you want to display

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
      {`${dataKeyValue}`}
    </text>
  );
};

const Analytics = () => {
  const attendeesColumns: GridColDef[] = [
    { field: "event_name", headerName: "Event Name", width: 200 },
    { field: "ticket_name", headerName: "Ticket Name", width: 200 },
    { field: "ticket_id", headerName: "Ticket ID", width: 100 },
    { field: "user_name", headerName: "User Name", width: 200 },
    { field: "purchase_date", headerName: "Purchase Date", width: 200 },
    { field: "price", headerName: "Price", width: 120 },
    { field: "promo_code", headerName: "Promo Code", width: 120 },
  ];
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [selectedEvent, setSelectedEvent] = useState<any>("");
  const [userDetails, setUserDetails] = useState<any>(null);
  const [analyticsData, setAnalyticsData] = useState<Array<any>>([]);
  const theme = useTheme();

const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  // const columns: GridColDef[] = [
  //   { field: "event_name", headerName: "Event Name", width: 200 },
  //   { field: "number_of_orders", headerName: "Number Of Orders", width: 250 },
  //   { field: "gross_sales", headerName: "Gross Sales", width: 200 },
  //   { field: "net_sales", headerName: "Net Sales", width: 200 },
  //   { field: "ticket_count", headerName: "Ticket Count", width: 100 },
  //   { field: "payment_owed", headerName: "Payment Owed", width: 100 },
  // ];
  const [sales, setSales] = useState<any>([]);
  const [ticketSales, setTicketSales] = useState<any>([]);

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);

        const ticketSalesRes = await apiGetRequest(`${urls.myTickets}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });

        if (ticketSalesRes?.code === "00") {
          // const filteredEvents = ticketSalesRes.data.filter((event: { event_name: any; }) => event.event_name === eventData.event_name);
          // console.log(
          //   "Hmmm::",
          //   ticketSalesRes.data.flatMap((event: any, index: number) =>
          //     event.ticket_details.flatMap((ticket: any) =>
          //       ticket.user_metrics.flatMap((user: any, userIndex: number) =>
          //         user.purchase_metrics.map(
          //           (purchaseMetric: any, purchaseIndex: number) => ({
          //             id: `${index}-${ticket.ticket_id}-${userIndex}-${purchaseIndex}`,
          //             event_name: event.event_name,
          //             event_id: event.event_id,
          //             // event_id: event.event_id,
          //             ticket_name: ticket.ticket_name,
          //             ticket_id: ticket.ticket_id,
          //             user_name: user.name,
          //             purchase_date: purchaseMetric.date_created,
          //             price: `${purchaseMetric.currency}${purchaseMetric.price}`,
          //             promo_code: purchaseMetric.promo_code,
          //           })
          //         )
          //       )
          //     )
          //   )
          // );
          setTicketSales(
            ticketSalesRes.data.flatMap((event: any, index: number) =>
              event.ticket_details.flatMap((ticket: any) =>
                ticket.user_metrics.flatMap((user: any, userIndex: number) =>
                  user.purchase_metrics.map(
                    (purchaseMetric: any, purchaseIndex: number) => ({
                      id: `${index}-${ticket.ticket_id}-${userIndex}-${purchaseIndex}`,
                      event_name: event.event_name,
                      event_id: event.event_id,
                      // event_id: event.event_id,
                      ticket_name: ticket.ticket_name,
                      ticket_id: ticket.ticket_id,
                      user_name: user.name,
                      purchase_date: purchaseMetric.date_created,
                      price: `${purchaseMetric.currency}${purchaseMetric.price}`,
                      promo_code: purchaseMetric.promo_code,
                    })
                  )
                )
              )
            )
          );
        }

        const myEventsMetricsRes = await apiGetRequest(
          `${urls.myEventsMetrics}`,
          {
            auth: userDetailsObject?.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );

        if (myEventsMetricsRes?.code === "00") {
          setSales(
            myEventsMetricsRes.data.flatMap((event: any, index: number) =>
              event.ticket_details.flatMap((ticket: any) => ({
                id: `${index}-${event.event_id}-${ticket.ticket_name}`,
                event_name: event.event_name,
                event_id: event.event_id,
                ticket_name: ticket.ticket_name,
                total_purchases: ticket.quantity_purchased.data,
                total_sales: ticket.total_sales,
              }))
            )
          );
        }

        const myEventsRes = await apiGetRequest(`${urls.analytics}`, {
          auth: userDetailsObject?.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        });

        if (myEventsRes?.code === "00") {
          // setAnalyticsData(myEventsRes.data);
          setAnalyticsData(myEventsRes.data);
          if (myEventsRes.data.length > 0) {
            setSelectedEvent(myEventsRes.data[0].event_id);
          }

          // setRows(
          //   myEventsRes.data.map((event: any, index: number) => ({
          //     id: index + 1,
          //     event_name: event.event_name,
          //     number_of_orders: event.number_of_orders,
          //     gross_sales: event.gross_sales_amount,
          //     net_sales: event.net_sales_amount,
          //     ticket_count: event.ticket_count,
          //     payment_owed: event.payment_owed,
          //   }))
          // );
        }
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };

  useEffect(() => {
    initFunct();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunct();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);
  
  return (
    <Container>
      <Helmet>
        <title>Analytics and Reporting</title>
      </Helmet>
      {/* {userDetails?<DataGridTable columns={columns} rows={rows} clickable={true} page="analytics"/>: <div style={{marginTop:"20vh"}}>
      <NoResults
              header={"Not Logged In!"}
              body={"Kindly login to view this page"}
              
            />
        </div>} */}

      <Row
        mainAxisAlignment="space-between"
        crossAxisAlignment="center"
        spacing={20}
        style={{ marginBottom: "2rem" }}
      >
        <Column>
          <HeaderGreenTopBar />
          <HeaderText>ANALYTICS</HeaderText>
        </Column>

        {analyticsData.length>0 && <StyledTextField2
          select
          variant="outlined"
          sx={{
            border: "none",

            "& fieldset": {
              border: "1px solid #79C2D8",
              borderRadius: "1000px",
              margin: 0,
            },
            width: "100%",
            maxWidth: "325px",
            margin: 0,
            borderRadius: "1000px",
            // background: "red",
          }}
          InputProps={{
            inputProps: {
              style: {
                textAlign: "left",
                borderRadius: "100rem",
                height: "20px",
                background: "red",
              },
            },
          }}
          SelectProps={{
            IconComponent: ChevronIconComponent, // Add your custom chevron icon here
          }}
          onChange={(e) => {
            setSelectedEvent(e.target.value);
          }}
          margin="normal"
          id="discount"
          label="Select Event"
          name="discount"
          value={selectedEvent}
        >
          {selectedEvent === "" && (
            <MenuItem value="" disabled>
              Select an event
            </MenuItem>
          )}
          {analyticsData?.map(
            (ad: { event_name: string; event_id: number }) => (
              <MenuItem value={ad.event_id}> {ad.event_name}</MenuItem>
            )
          )}
        </StyledTextField2>}
      </Row>

      {analyticsData.length>0?<Column>
        <Row
        mainAxisAlignment="space-between"
        crossAxisAlignment="center"
        style={{ marginBottom: "2rem" }}
        spacing={20}
      >
        <Column>
          <HeaderText
            style={{ color: "rgba(12, 175, 109, 1)", marginBottom: 5 }}
          >
            TICKET SALES
          </HeaderText>
        </Column>

        <RowNoMinMax spacing={20}>
          {[
            { name: "Allocation", color: "rgba(237, 242, 247, 1)" },
            { name: "Sold", color: "rgba(12, 175, 109, 1)" },
          ].map((val: any, index: any) => (
            <RowNoMinMax spacing={10} crossAxisAlignment="center">
              {" "}
              <div
                style={{
                  background: val.color,
                  width: "70px",
                  height: "30px",
                  minWidth: "70px",
                  minHeight: "30px",
                }}
              ></div>
              <span>{val.name}</span>
            </RowNoMinMax>
          ))}
        </RowNoMinMax>
      </Row>

      <div
        style={{
          width: "100%",
          height: "500px",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        {" "}
        {/* Ensure the parent has a height */}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={
              selectedEvent
                ? analyticsData
                    .find((ad) => ad.event_id === selectedEvent)
                    .ticket_count.map((tic: any) => ({
                      name: tic.ticket_name,
                      "Tickets Sold": tic.quantity_sold ?? 0,
                      Quantity: tic.quantity ?? 0,
                    }))
                : []
            }
            margin={{
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            {/**@ts-ignore */}
            <Tooltip content={CustomTooltip} />
            {/* <Legend /> */}
            {/* <Bar dataKey="Tickets Sold" fill="rgba(12, 175, 109, 1)" barSize={95} label='hello'/> */}
            <Bar
              dataKey="Tickets Sold"
              stackId="a"
              fill="rgba(12, 175, 109, 1)"
              barSize={95}
            />
            <Bar
              dataKey="Quantity"
              stackId="a"
              fill="rgba(237, 242, 247, 1)"
              color="red"
              barSize={95}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <RowNoMinMax mainAxisAlignment="space-between" spacing={20}>
        <HeaderText style={{ color: "rgba(12, 175, 109, 1)", marginBottom: 5 }}>
          REVENUE
        </HeaderText>
        <HeaderText>
          TOTAL REVENUE:{" "}
          {selectedEvent
            ? parseFloat(
                `${
                  analyticsData.find((ad) => ad.event_id === selectedEvent)
                    .gross_sales_amount ?? 0
                }`
              ).toPrecision(3)
            : "???"}
        </HeaderText>
      </RowNoMinMax>

      <RowNoMinMax sx={{ marginBottom: "50px", ...(isMatch?{flexDirection:"column", alignItems:"end"}:{}) }}>
        <div
          style={{
            width: "100%",
            height: "100vh", // Responsive to viewport height
            marginTop: "50px",
            marginBottom: "50px",
            position: "relative",
          }}
        >
          {selectedEvent &&
          sales.filter((ad: any) => ad.event_id === selectedEvent).some((x: any)=> x.total_sales > 0) ? (
            <ResponsiveContainer >
              <PieChart>
                <Pie
                  data={
                    selectedEvent
                      ? sales
                          .filter((ad: any) => ad.event_id === selectedEvent)
                          .map((tic: any) => ({
                            name: tic.ticket_name,
                            "Tickets Sold": tic.total_sales,
                          }))
                      : []
                  }
                  cx="50%"
                  cy="50%"
                  outerRadius="80%" // Responsive radius
                  innerRadius="40%" // Adjusted for responsive design
                  labelLine={false}
                  label={({
                    cx,
                    cy,
                    midAngle,
                    innerRadius,
                    outerRadius,
                    percent,
                    index,
                  }: any) => {
                    const RADIAN = Math.PI / 180;
                    const radius =
                      innerRadius + (outerRadius - innerRadius) * 0.5;
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                    const data = selectedEvent
                      ? sales
                          .filter((ad: any) => ad.event_id === selectedEvent)
                          .map((tic: any) => ({
                            name: tic.ticket_name,
                            "Tickets Sold": tic.total_sales,
                          }))
                      : [];

                    const dataKeyValue = data[index]?.name || "";

                    return (
                      <text
                        x={x}
                        y={y}
                        fill="white"
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{ fontSize: "16px" }}
                      >
                        <tspan x={x} dy="-0.5em">{`${(percent * 100).toFixed(
                          0
                        )}%`}</tspan>
                        {dataKeyValue.split(" ").map((val: any) => (
                          <tspan key={val} x={x} dy="1.2em">
                            {val}
                          </tspan>
                        ))}
                      </text>
                    );
                  }}
                  dataKey="Tickets Sold"
                >
                  {sales.map((entry: any, index: number) => (
                    <Cell
                    style={{outline:"none"}}

                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <NoResults
              header={"Nothing yet!"}
              body={"No data available"}
            />
          )}
        </div>

        <Column spacing={20} sx={{ mt: 5 }}>
          {selectedEvent &&
          analyticsData.find((ad) => ad.event_id === selectedEvent)
            ?.ticket_count?.length > 0 ? (
            analyticsData
              .find((ad) => ad.event_id === selectedEvent)
              ?.ticket_count?.map((tic: any) => ({
                name: tic.ticket_name,
                "Tickets Sold": tic.quantity_sold ?? 0,
                Quantity: tic.quantity ?? 0,
              }))
              .map((val: any, index: any) => (
                <Row key={val.name} spacing={10} crossAxisAlignment="center">
                  <div
                    style={{
                      background: COLORS[index % COLORS.length],
                      width: "70px",
                      height: "30px",
                      minWidth: "70px",
                      minHeight: "30px",
                    }}
                  ></div>
                  <span>{val.name}</span>
                </Row>
              ))
          ) : (
            <div
              style={{
                color: "#888",
                fontSize: "18px",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              No data available
            </div>
          )}
        </Column>
      </RowNoMinMax>

      <RowNoMinMax spacing={20} mainAxisAlignment="space-between">
        <HeaderText style={{ color: "rgba(12, 175, 109, 1)", marginBottom: 5 }}>
          PAYMENTS
        </HeaderText>
        <HeaderText>
          TOTAL PAYMENTS:{" "}
          {selectedEvent
            ? parseFloat(
                `${
                  analyticsData.find((ad) => ad.event_id === selectedEvent)
                    .payment_owed ?? 0
                }`
              ).toPrecision(3)
            : "???"}
        </HeaderText>
      </RowNoMinMax>

      <RowNoMinMax sx={{ marginBottom: "50px", ...(isMatch?{flexDirection:"column", alignItems:"end"}:{}) }}>
        <div
          style={{
            width: "100%",
            height: "100vh",
            marginTop: "50px",
            position: "relative",
          }}
        >
          {selectedEvent &&
          analyticsData.find((ad) => ad.event_id === selectedEvent)
            ?.ticket_count?.some((x: any)=> x.quantity_sold > 0)? (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={
                    analyticsData
                      .find((ad) => ad.event_id === selectedEvent)
                      ?.ticket_count?.map((tic: any) => ({
                        name: tic.ticket_name,
                        "Tickets Sold": tic.quantity_sold ?? 0,
                        Quantity: tic.quantity ?? 0,
                      })) || [] 
                  }
                  cx="50%"
                  cy="50%"
                  outerRadius="80%" // Use percentages for responsive scaling
                  labelLine={false}
                  label={({
                    cx,
                    cy,
                    midAngle,
                    innerRadius,
                    outerRadius,
                    percent,
                    index,
                  }: any) => {
                    const RADIAN = Math.PI / 180;
                    const radius =
                      innerRadius + (outerRadius - innerRadius) * 0.5;
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                    const data =
                      analyticsData
                        .find((ad) => ad.event_id === selectedEvent)
                        ?.ticket_count?.map((tic: any) => ({
                          name: tic.ticket_name,
                          "Tickets Sold": tic.quantity_sold ?? 0,
                          Quantity: tic.quantity ?? 0,
                        })) || [];

                    const dataKeyValue = data[index]?.name || "";

                    return (
                      <text
                        x={x}
                        y={y}
                        fill="white"
                        textAnchor="middle"
                        dominantBaseline="central"
                      >
                        <tspan x={x} dy="-0.5em">{`${(percent * 100).toFixed(
                          0
                        )}%`}</tspan>
                        {dataKeyValue.split(" ").map((val: any) => (
                          <tspan key={val} x={x} dy="1.2em">
                            {val}
                          </tspan>
                        ))}
                      </text>
                    );
                  }}
                  dataKey="Tickets Sold"
                >
                  {analyticsData
                    .find((ad) => ad.event_id === selectedEvent)
                    ?.ticket_count?.map((entry: any, index: number) => (
                      <Cell
                        style={{outline:"none"}}
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <NoResults
              header={"Nothing yet!"}
              body={"No data available"}
            />
          )}
        </div>

        <Column spacing={20} sx={{ mt: 5 }}>
          {(selectedEvent
            ? analyticsData
                .find((ad) => ad.event_id === selectedEvent)
                ?.ticket_count?.map((tic: any) => ({
                  name: tic.ticket_name,
                  "Tickets Sold": tic.quantity_sold ?? 0,
                  Quantity: tic.quantity ?? 0,
                })) || []
            : []
          ).map((val: any, index: any) => (
            <Row key={val.name} spacing={10} crossAxisAlignment="center">
              <div
                style={{
                  background: COLORS[index % COLORS.length],
                  width: "70px",
                  height: "30px",
                  minWidth: "70px",
                  minHeight: "30px",
                }}
              ></div>
              <span>{val.name}</span>
            </Row>
          ))}
        </Column>
      </RowNoMinMax>

      <Row
        mainAxisAlignment="space-between"
        sx={{
          marginBottom: 2,
        }}
      >
        <HeaderText
          style={{
            color: "rgba(12, 175, 109, 1)",
          }}
        >
          ATTENDEES
        </HeaderText>
      </Row>

      {selectedEvent && (
        <DataGridTable
          columns={attendeesColumns}
          rows={ticketSales.filter((ts: any) => ts.event_id == selectedEvent).reverse()}
          page="analytics"
        />
      )}</Column>:<div
      style={{
        color: "#888",
        fontSize: "24px",
        display:"flex",
        justifyContent:"center", alignItems:"center"
      }}
    >
      No data available
    </div>}
    </Container>
  );
};

export default Analytics;
