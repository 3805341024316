import React, { useCallback, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  styled,
} from "@mui/material";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../../_shared/types";
import { apiPostRequest } from "../../_shared/api";
import { urls } from "../../_shared/constants";
import { ContentTypeEnum } from "../../_shared/enum/content-type.enum";
import { toast } from "react-toastify";
import { ErrorSeverityEnum } from "../../_shared/enum/error-severity.enum";

interface WaitlistButtonProps {
  eventId: number;
  ticketId: number;
  isUserLoggedIn: boolean;
  onSuccess?: () => void;
}

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: "16px",
    backgroundColor: "rgba(37, 94, 135, 1)",
  },
});

const StyledDialogTitle = styled(DialogTitle)({
  fontSize: "1.25rem",
  fontWeight: 600,
  color: "#FFFFFF",
  padding: "24px 24px 16px 24px",
});

const StyledDialogContent = styled(DialogContent)({
  padding: "0 24px 16px 24px",
  "& .MuiTypography-root": {
    color: "#FFFFFF",
    lineHeight: 1.5,
  },
});

const StyledDialogActions = styled(DialogActions)({
  padding: "16px 24px 24px",
  "& > button": {
    borderRadius: "100px",
    padding: "8px 24px",
    textTransform: "none",
    fontSize: "0.95rem",
  },
});

const WaitlistButtonStyled = styled(Button)({
  backgroundColor: "#0CAF6D",
  color: "white",
  borderRadius: "100px",
  padding: "8px 20px",
  textTransform: "none",
  fontSize: "1rem",
  fontWeight: 400,
  minWidth: "120px",
  height: "40px",
  gap: "4px",
  "&:hover": {
    backgroundColor: "#099c61",
  },
  "& .plus-icon": {
    fontSize: "1.2rem",
    marginRight: "2px",
  },
});

const ActionButton = styled(Button)({
  textTransform: "none",
  borderRadius: "100px",
  padding: "8px 24px",
  fontWeight: 500,
  fontSize: "0.95rem",
  boxShadow: "none",
  "&:hover": {
    boxShadow: "none",
  },
});

const cancelButtonSx = {
  borderColor: "#0CAF6D",
  color: "white",
  "&:hover": {
    borderColor: "#099c61",
    backgroundColor: "rgba(12, 175, 109, 0.04)",
  },
} as const;

const joinButtonSx = {
  backgroundColor: "#0CAF6D",
  "&:hover": {
    backgroundColor: "#099c61",
  },
} as const;

const WaitlistButton: React.FC<WaitlistButtonProps> = React.memo(
  ({ eventId, ticketId, isUserLoggedIn, onSuccess }) => {
    const [state, dispatch] = React.useReducer(
      (
        state: { open: boolean; success: boolean; successMessage: string },
        action: { type: "OPEN" | "CLOSE" | "SUCCESS"; payload?: string }
      ) => {
        switch (action.type) {
          case "OPEN":
            return { ...state, open: true };
          case "CLOSE":
            return {
              ...state,
              open: false,
              success: false,
              successMessage: "",
            };
          case "SUCCESS":
            return {
              ...state,
              success: true,
              successMessage: action.payload || "",
            };
          default:
            return state;
        }
      },
      { open: false, success: false, successMessage: "" }
    );

    const handleButtonClick = useCallback(() => {
      if (!isUserLoggedIn) {
        EventRegister.emit(EventsEnum.LoginUser, true);
        return;
      }
      dispatch({ type: "OPEN" });
    }, [isUserLoggedIn]);

    const handleWaitlistJoin = useCallback(async () => {
      try {
        const storedData = localStorage.getItem("userDetails");
        const userDetailsObject = storedData ? JSON.parse(storedData) : null;

        const response = await apiPostRequest(
          `${urls.events}join-waitlist/${eventId}`,
          {},
          {
            auth: userDetailsObject?.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );

        if (response?.code === "00") {
          dispatch({ type: "SUCCESS", payload: response?.msg });
          onSuccess?.();
        } else {
          toast.error(response?.msg, {
            type: ErrorSeverityEnum.error,
          });
        }
      } catch (error) {
        console.error("Failed to join waitlist:", error);
      }
    }, [eventId, ticketId, onSuccess]);

    const handleClose = useCallback(() => {
      dispatch({ type: "CLOSE" });
    }, []);

    const dialogContent = useMemo(
      () =>
        state.success
          ? state.successMessage
          : "Would you like to join the waitlist for this event? We'll notify you when tickets become available.",
      [state.success, state.successMessage]
    );

    return (
      <>
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <span style={{ color: "#EB352C", fontWeight: "bold" }}>
            SOLD OUT!
          </span>
          <WaitlistButtonStyled onClick={handleButtonClick}>
            <span className="plus-icon">+</span>
            Waitlist
          </WaitlistButtonStyled>
        </div>

        <StyledDialog
          open={state.open}
          onClose={handleClose}
          maxWidth="xs"
          fullWidth
        >
          <StyledDialogTitle>
            {state.success ? "Success!" : "Join Waitlist"}
          </StyledDialogTitle>
          <StyledDialogContent>
            <Typography>{dialogContent}</Typography>
          </StyledDialogContent>
          <StyledDialogActions>
            <ActionButton
              onClick={handleClose}
              variant="contained"
              sx={cancelButtonSx}
            >
              {state.success ? "Close" : "Cancel"}
            </ActionButton>
            {!state.success && (
              <ActionButton
                onClick={handleWaitlistJoin}
                variant="contained"
                sx={joinButtonSx}
              >
                Join Waitlist
              </ActionButton>
            )}
          </StyledDialogActions>
        </StyledDialog>
      </>
    );
  }
);

WaitlistButton.displayName = "WaitlistButton";

export default WaitlistButton