import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import packageVersion from "../package.json";
import { Route, Routes, useLocation } from "react-router-dom";
import EventsHome from "./features/Home/eventsHome";
import EventDetails from "./features/EventDetails/eventDetails";
import Header from "./features/_shared/components/header";
import {
  Avatar,
  Backdrop,
  CircularProgress,
  InputAdornment,
  ThemeProvider,
  Typography,
  styled,
} from "@mui/material";
import Footer from "./features/_shared/components/footer";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "./features/_shared/types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentSuccessPage from "./features/SuccessFailure/paymentSuccess";
import { lightTheme } from "./themes";
import PaymentFailedPage from "./features/SuccessFailure/paymentFailed";
import EventAdmin from "./features/Event_Admin/EventAdmin";
import MyTickets from "./features/My_Tickets/myTickets";
import trackPathForAnalytics from "./features/firebase/TrackPageForAnalytics";
import CreateEventPage from "./features/Event_Admin/CreateEvent";
import { Row } from "./features/_shared/styledComponents";
import SidePanel from "./features/SidePanel/SidePanel";
import MyEventSales from "./features/Event_Sales/eventSales";
import MyTicketSales from "./features/Event_Sales/ticketSales";
import PaymentConfirmation from "./features/Payment_Confirmation/PaymentConfirmation";
import { BodyText2 } from "./features/_shared/styled";
import PencilSvg from "./features/_shared/assets/svg/pencil.svg";
import SearchIcon from "./features/_shared/assets/svg/Search.svg";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AuthPage, { StyledTextField } from "./features/Auth/AuthPage";
import { apiPostRequest } from "./features/_shared/api";
import { ContentTypeEnum } from "./features/_shared/enum/content-type.enum";
import VaryingWidthImage from "./features/_shared/components/imageViewer";
import { ErrorSeverityEnum } from "./features/_shared/enum/error-severity.enum";
import MyEvents from "./features/Event_Admin/MyEvents";
import EventInformation from "./features/Home/EventInformation";
import Analytics from "./features/Event_Admin/Analytics";
import EventCheckout from "./features/EventDetails/checkout";
import PromoCodes from "./features/Event_Admin/PromoCodes";
import MEFCheckout from "./features/EventDetails/MefCheckout";
import ConfirmationModal from "./features/_shared/components/ConfirmationModal";
import QrCodePage from "./features/Home/css/QrCodePage";
import { errorMessage } from "./features/_shared/constants";
import MyAccountPage from "./features/Event_Admin/MyAccount";
import ScrollToTop from "./ScrollToTop";
import Notifications from "./features/Notifications/Notifications";
import MicroHomePage from "./features/Home/Qasa_Microsite/MicroHomePage";
import MicroContactPage from "./features/Home/Qasa_Microsite/MicroContact";
import MicroDownloadPage from "./features/Home/Qasa_Microsite/MicroDownload";
import MicroTermsOfService from "./features/Home/Qasa_Microsite/MicroTermsOfService";
import MicroFAQ from "./features/Home/Qasa_Microsite/MicroFAQ";
import MicroPrivacyPolicy from "./features/Home/Qasa_Microsite/MicroPolicy";
import MicrositeLayout from "./features/Home/Qasa_Microsite/MicrositeLayout";
import AdvertisementsPage from "./features/Adverts/Adverts";

const PAGES_TO_SHOW_SEARCH = ["/",'/my-events', '/event-admin'];

const Input = styled("input")({
  display: "none",
});
function App() {
  const location = useLocation();
  const isMEFCheckout = location.pathname === "/mef/checkout";
  const isMicrosite =
    location.pathname.startsWith("/i") &&
    (location.pathname.length === 2 || location.pathname[2] === "/");
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [speedbumpEvent, setSpeedbumpEvent] = useState<any>();
  const [speedbumpData, setSpeedbumpData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { pathname, search } = useLocation();
  const [userDetails, setUserDetails] = useState<any>(null);
  const [subscribedCheckLoginStatusEvent, setSubscribedCheckLoginStatusEvent] =
    useState<any>();
  const [openCreateEvent, setOpenCreateEvent] = useState<any>(false);
  const [showModal, setShowModal] = useState(false);
  const [subscribedLoginEvent, setSubscribedLoginEvent] = useState<any>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const handleUploadUserProfile = async (image: string) => {
    const formData = new FormData();
    const response = await fetch(image);
    const blob = await response.blob();
    formData.append("file", blob, `${Date.now()}.${blob.type.split("/")[1]}`);
    formData.append("file_type", "profile");
    // formData.append("id", `${userDetails.id}`);

    const updateImageRes = await apiPostRequest(
      "/users/change_profile",
      formData,
      {
        auth: userDetails.access_token,
        content_type: ContentTypeEnum.MULTIPART_FORM,
      }
    );

    if (updateImageRes?.code == "00") {
      toast(updateImageRes?.msg ?? "Updated image", {
        type: ErrorSeverityEnum.success,
      });
      localStorage.setItem(
        "userDetails",
        JSON.stringify({ ...userDetails, avatar: updateImageRes?.data?.avatar })
      );

      window.location.reload();
    } else {
      toast(errorMessage, {
        type: ErrorSeverityEnum.error,
      });
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        setImagePreview(result);

        handleUploadUserProfile(result); // Send the image path to the parent component
      };
      reader.readAsDataURL(file);
    }
  };

  const analytics = useCallback(() => {
    trackPathForAnalytics({
      path: pathname,
      search: search,
      title: pathname.split("/")[1],
    });
  }, [pathname, search]);

  const initFunction = async () => {
    setUserDetails(null);
    const storedData = localStorage.getItem("userDetails");

    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
      }
    }
  };

  useEffect(() => {
    analytics();
  }, [analytics]);

  useEffect(() => {
    initFunction();
    setSubscribedCheckLoginStatusEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunction();
        }
      )
    );
    setSubscribedEvent(
      EventRegister.addEventListener(EventsEnum.Loading, (data: boolean) => {
        setLoading(data);
      })
    );
    setSpeedbumpEvent(
      EventRegister.addEventListener(
        EventsEnum.Speedbump,
        (data: { text: string; function: () => void }) => {
          setSpeedbumpData(data);
          // setSpeedbumpFunction(data.function());
          setShowModal(true);
        }
      )
    );
    console.log(`Current version: ${packageVersion.version}`);
    return () => {
      EventRegister.removeEventListener(subscribedCheckLoginStatusEvent);
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);

  if (isMicrosite) {
    return (
      <MicrositeLayout>
        <Routes>
          <Route path="/i" element={<MicroHomePage />} />
          <Route path="/i/" element={<MicroHomePage />} />
          <Route path="/i/contact" element={<MicroContactPage />} />
          <Route path="/i/downloads" element={<MicroDownloadPage />} />
          <Route path="/i/terms-of-service" element={<MicroTermsOfService />} />
          <Route path="/i/faq" element={<MicroFAQ />} />
          <Route path="/i/privacy-policy" element={<MicroPrivacyPolicy />} />
        </Routes>
      </MicrositeLayout>
    );
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {!isMEFCheckout && (
          <>
            <Backdrop sx={{ color: "#fff", zIndex: 30000 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <ConfirmationModal
              open={showModal}
              onClose={() => setShowModal(false)}
              question={speedbumpData?.text ?? ""}
              onConfirm={() => speedbumpData?.function()}
            />
            <Header />
          </>
        )}

        <Row
          spacing={20}
          style={{
            flex: 1,
            margin: 0,
            width: "100%",
            height: "100%",
          }}
        >
          {!isMEFCheckout && userDetails && (
            <SidePanel userDetails={userDetails} />
          )}
          <div
            style={{
              // overflow: "auto",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: isMEFCheckout ? "0" : "90px",
              flex: 1,
              paddingRight: "20px",
              position: "relative",
              minHeight: `calc(100vh - ${isMEFCheckout ? "0px" : "90px"})`,
            }}
          >
            <div
              style={{
                padding: "0 20px",
                paddingLeft: "40px",
                maxWidth: "1200px",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                position: "relative",
                minHeight: `calc(100vh - ${isMEFCheckout ? "0px" : "165px"})`,
                boxSizing: "border-box",
                paddingBottom: "100px",
              }}
            >
              {!isMEFCheckout &&
                !window.location.pathname.startsWith("/login") && (
                  <div
                    style={{
                      marginBottom: "3rem",
                      gap: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: userDetails ? "space-between" : "center",
                      boxSizing: "border-box",
                    }}
                  >
                    {userDetails && (
                      <div style={{ width: "70px", height: "70px" }} />
                    )}
                    { PAGES_TO_SHOW_SEARCH.some((path)=> path == pathname) && <div
                      style={{
                        borderRadius: "100rem",
                        background: "#EDF2F7",
                        width: "100%",
                        height: "50px",
                        maxWidth: "600px",
                        marginTop: "15px",
                      }}
                    >
                      <StyledTextField
                        variant="outlined"
                        sx={{
                          "& fieldset": {
                            // border: "1px solid #79C2D8",
                            borderRadius: "100rem",
                            margin: 0,
                          },
                          width: "100%",
                          maxWidth: "600px",
                          margin: 0,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <img
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  marginRight: "5px",
                                }}
                                src={SearchIcon}
                              />
                            </InputAdornment>
                          ),
                          inputProps: {
                            style: {
                              textAlign: "left",
                              borderRadius: "100rem",
                              height: "20px",
                            },
                          },
                        }}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                        margin="normal"
                        id="search"
                        label="Search"
                        name="search"
                      />
                    </div>}
                    {userDetails && (
                      <div
                        style={{
                          zIndex: "1000",
                          borderRadius: "100rem",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <BodyText2
                          style={{
                            fontSize: "14px",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                          }}
                        >
                          Hello
                          <strong>
                            {` ${
                              `${userDetails?.name ?? "User One"}`.split(" ")[0]
                            }!`}{" "}
                          </strong>
                        </BodyText2>
                        <div
                          style={{
                            position: "relative",
                            width: "70px",
                            height: "70px",
                          }}
                        >
                          <Avatar sx={{ width: 66, height: 66 }}>
                            {/* {`${userDetails?.name}`[0]} */}
                            <VaryingWidthImage
                              blurImageUrl={userDetails?.avatar}
                              imageUrl={userDetails?.avatar}
                            />
                          </Avatar>
                          <label
                            htmlFor="image-upload"
                            style={{ cursor: "pointer" }}
                          >
                            <Input
                              accept="image/*"
                              id="image-upload"
                              type="file"
                              onChange={handleImageChange}
                            />
                            <div
                              style={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                width: "25px",
                                height: "25px",
                                background: "#38A169",
                                borderRadius: "200rem",
                                border: "3px solid white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: "1000",
                              }}
                            >
                              {/* <img
                            style={{
                              width: "18px",
                              height: "18px",
                              transform: "scale(0.8)",
                            }}
                            src={PencilSvg}
                          /> */}
                              <CameraAltIcon
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  transform: "scale(0.8)",
                                  color: "white",
                                }}
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              <div style={{ flex: 1 }}>
                <ScrollToTop />
                <Routes>
                  <Route
                    path="/"
                    element={<EventsHome searchTerm={searchTerm} />}
                  />
                  <Route path="/login" element={<AuthPage />} />
                  <Route path="/e/:purl" element={<EventDetails />} />
                  <Route path="/event-details" element={<EventDetails />} />
                  <Route
                    path="/event-admin"
                    element={<EventAdmin searchTerm={searchTerm} />}
                  />
                  <Route
                    path="/my-events"
                    element={<MyEvents searchTerm={searchTerm} />}
                  />
                  <Route path="/create-event" element={<CreateEventPage />} />
                  {/* <Route path="/adverts" element={<AdvertisementsPage />} /> */}
                  <Route path="/event-sales" element={<MyEventSales />} />
                  <Route path="/ticket-sales" element={<MyTicketSales />} />
                  <Route path="/my-tickets" element={<MyTickets />} />
                  <Route path="/promo-codes" element={<PromoCodes />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="/analytics" element={<Analytics />} />
                  <Route path="/checkout" element={<EventCheckout />} />
                  <Route path="/mef/checkout" element={<MEFCheckout />} />
                  <Route
                    path="/payment-success"
                    element={<PaymentSuccessPage />}
                  />
                  <Route path="/ea/:purl" element={<EventInformation />} />
                  <Route
                    path="/payment-failed"
                    element={<PaymentFailedPage />}
                  />
                  <Route
                    path="/payment-confirmation"
                    element={<PaymentConfirmation />}
                  />
                  <Route path="/qr-code" element={<QrCodePage />} />
                  <Route path="/my-account" element={<MyAccountPage />} />
                </Routes>
              </div>

              <div style={{ marginBottom: "10rem" }}></div>
              {!loading && !isMEFCheckout && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    boxSizing: "border-box",
                    background: "#fff",
                  }}
                >
                  <Footer />
                </div>
              )}
              <ToastContainer
                position="top-center"
                toastStyle={{
                  borderRadius: "15px",
                  background: "rgba(37, 94, 135, 0.9)",
                  color: "white",
                  width: "517px",
                  height: "98px",
                  fill: "white",
                }}
                hideProgressBar
                icon={false}
              />
            </div>
          </div>
        </Row>
      </div>
    </ThemeProvider>
  );
}

export default App;
